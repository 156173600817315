// extracted by mini-css-extract-plugin
export var container = "aboutMain-module--container--FqTsU";
export var headline = "aboutMain-module--headline--3Af3Q";
export var title = "aboutMain-module--title--38bfu";
export var textContainer = "aboutMain-module--textContainer--YsSZ8";
export var textBig = "aboutMain-module--textBig--1HXkR";
export var textSmall = "aboutMain-module--textSmall--3Hevl";
export var images = "aboutMain-module--images--2KT1W";
export var juditContainer = "aboutMain-module--juditContainer--3KKzZ";
export var juditText = "aboutMain-module--juditText--jfR3C";
export var juditImage = "aboutMain-module--juditImage--3qKXg";
export var leftMargin = "aboutMain-module--leftMargin--tl7lC";
export var wrapper = "aboutMain-module--wrapper--nl15k";
export var box = "aboutMain-module--box--bbTUA";