import React from "react";
import { cn } from "../lib/helpers";

import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import PortableText from "./portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import BrushImage from "./brushImage";
import LetterText from "./letterText";
import FadeInSection from "./FadeIn";

import * as styles from "./aboutMain.module.css";
import * as commonStyles from "../styles/common.module.css";

function AboutMain({ aboutData, teamData = [] }) {
  const { images, _rawBodyBig, _rawBodySmall } = aboutData;
  let judit;
  let boris;
  let team = [];

  teamData.forEach((member) => {
    if (member._id === "6f1f56c7-df35-47e6-8171-a014436a5c7a") {
      judit = member;
    } else if (member._id === "624df32d-0900-4d44-8964-70646fa01f8e") {
      boris = member;
    } else {
      team.push(member);
    }
  });

  return (
    <div className={styles.container}>
      <FadeInSection>
        <h1
          className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        >
          O nás
        </h1>
        <div>
          <div className={cn(styles.textBig, commonStyles.fontButler, commonStyles.colorDarkGray)}>
            <PortableText blocks={_rawBodyBig} />
          </div>
          <div
            className={cn(styles.textSmall, commonStyles.fontButler, commonStyles.colorDarkGray)}
          >
            <PortableText blocks={_rawBodySmall} />
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className={styles.images}>
          {images.map((image, index) => {
            const imageData = getGatsbyImageData(image, {}, clientConfig.sanity);
            return (
              <div key={`image_${index}`}>
                <GatsbyImage image={imageData} alt={image.alt} />
              </div>
            );
          })}
        </div>
      </FadeInSection>
      {judit && boris && (
        <>
          <FadeInSection>
            <div className={styles.juditContainer}>
              <div className={cn(styles.juditImage, styles.leftMargin)}>
                <BrushImage image={judit.image} />
              </div>
              <div className={styles.juditText}>
                <LetterText
                  letter={judit.letter}
                  blocks={judit._rawBio}
                  title={judit.name}
                  subtitle={judit.subtitle}
                />
              </div>
            </div>
          </FadeInSection>

          <FadeInSection>
            <div className={styles.juditContainer}>
              <div className={cn(styles.juditText, styles.leftMargin)}>
                <LetterText
                  letter={boris.letter}
                  blocks={boris._rawBio}
                  title={boris.name}
                  subtitle={boris.subtitle}
                />
              </div>
              <div className={styles.juditImage}>
                <BrushImage image={boris.image} />
              </div>
            </div>
          </FadeInSection>

          <div className={styles.wrapper}>
            {team.map((member, indexM) => (
              <div key={`member_${indexM}`} className={styles.box}>
                <FadeInSection>
                  <LetterText
                    letter={member.letter}
                    blocks={member._rawBio}
                    title={member.name}
                    subtitle={member.subtitle}
                    small
                  />
                </FadeInSection>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AboutMain;
